import React, { useEffect, useRef, useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Arrow from '../../images/page-services/arrow.inline.svg';
import { useTranslation } from 'react-i18next';

const ScrollTo = ({ scrollOnPage }) => {
  const { t } = useTranslation();

  const [showScrollBtn, setShowScrollBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let windowHeight = window.innerHeight;

      window.onscroll = () => {
        let top = document.documentElement.scrollTop || document.body.scrollTop;

        if (top > (windowHeight/2)) {
          setShowScrollBtn(true);
        } else {
          setShowScrollBtn(false);
        }
      };
    };
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Link 
      className={`scroll-down ${showScrollBtn ? " active" : ""}`}
      to="header-wrap"
      smooth={true}
      duration={1000}
      offset={-80}
    >
      <div className="scroll-down__iconUp">
        <Arrow alt="arrow" />
      </div>
    </Link>
  );
};

export default ScrollTo;
