import { globalHistory } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import logo from '../../images/faceit-logo-white.svg';
import fb from '../../images/footer/icon-fb.svg';
import linkedIn from '../../images/footer/icon-linked.svg';
import instagram from '../../images/footer/icon-Instagram.svg';
import mail from '../../images/footer/icon-mail.svg';
import {languages} from "../Common/footer&header/filtratorFunc";
import {backOptions, langButtons, options} from "../Common/footer&header/structureButtons";
import { window } from 'browser-monads';

const Footer = () => {
  const pageLocation = globalHistory?.location.pathname.split('/')[2]
  const langFromQuery = globalHistory?.location.pathname.split('/')[1];
  const vacancyStrForCheck = 'vacancy'

  const [lang, setLange] = useState('');
  const [langs] = useState(languages(langButtons, pageLocation, vacancyStrForCheck, 'footer'));
  const { i18n } = useTranslation();

  useEffect(() => {
    setLange(langFromQuery);
  }, []);

  const updateLang = (item) => {
    setLange(item);
    navigate(
      `/${item}/${globalHistory.location.pathname
        .split('/')
        .slice(2)
        .join('/')}`
    );
  };
  const currentLocation = window.location.pathname.slice(4);
  const isLocation = pageLocation === currentLocation ? true : false; 

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row align-items-left" id="footer-bottom-content">
            <div className="col-md-12 col-lg-3 footer-text">
              <div className="pb-4">
                <a href={`/${lang}/`} className="footer-logo">
                  <img src={logo} alt="" />
                </a>
              </div>
              <div className="footer-description">
                <Trans i18nKey="footer" />
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1">
              <div className="row" id="footer-row-content">
                <div className="col-md-4 col-lg-4">
                  <ul className="footer-links">
                    <Link to={`/${lang}/about-us`} className={`${"about-us" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.aboutUs" />
                    </Link>
                    <Link to={`/${lang}/services`} className={`${"services" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.services" />
                    </Link>
                    <Link to={`/${lang}/portfolio`} className={`${"portfolio" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.portfolio" />
                    </Link>
                    {i18n.t('menu.vacancies') &&
                      i18n.t('menu.vacancies') !== '' && (
                        <Link to={`/${lang}/vacancy`} className="nav-link">
                          <Trans i18nKey="menu.vacancies" />
                        </Link>
                      )}
                  </ul>
                </div>
                <div className="col-md-4 col-lg-4">
                  <ul className="footer-links">
                    <Link to={`/${lang}/how-we-work`} className={`${"how-we-work" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.howWeWork" />
                    </Link>
                    <Link to={`/${lang}/testimonials`} className={`${"testimonials" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.testimonials" />
                    </Link>
                    <Link to={`/${lang}/contact-us`} className={`${"contact-us" === currentLocation && 'footer-description__active'}`}>
                      <Trans i18nKey="menu.contacts" />
                    </Link>
                  </ul>
                </div>
                <div className="col-md-4 col-lg-4">
                  <ul className="footer-links footer-social-links d-flex flex-md-column justify-content-center mt-3 mt-md-0">
                    <li>
                      <Link to="https://www.facebook.com/FaceItUa/">
                        <img src={fb} alt="" />
                        <span className="d-none d-md-block">Facebook</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/company/face-it/">
                        <img src={linkedIn} alt="" />
                        <span className="d-none d-md-block">LinkedIn</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/faceit_ua/">
                        <img src={instagram} alt="" />
                        <span className="d-none d-md-block">Instagram</span>
                      </Link>
                    </li>
                    <li>
                      <Link href="mailto:info@faceit.com.ua">
                        <img src={mail} alt="" />
                        <span className="d-none d-md-block">
                          info@faceit.com.ua
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="order-2 order-md-1 mt-2 mt-md-0">
              © Copyright FaceIT, 2009-{new Date().getFullYear()}
            </div>
            <div className="order-1 order-md-2">
              <div className="d-flex align-items-center justify-content-center ">
                <div>Language:</div>
                <Dropdown className="lang" navbar={true}>
                  <Dropdown.Toggle
                    className="dropdown-toggle lang-btn"
                    as="button"
                    bsPrefix=" "
                  >
                    {options[lang]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right footer-dropdown">
                    {langs.map(
                      (item) =>
                        item !== lang && (
                          <Dropdown.Item
                            href="#"
                            key={item}
                            onClick={() => updateLang(backOptions[item])}
                            className="dropdown-item"
                          >
                            {item}
                          </Dropdown.Item>
                        )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
