import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { window } from 'browser-monads';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(() => window.innerWidth);
    }

    const throttledResizeHandler = throttle(handleResize, 250);
    
    window.addEventListener("resize", throttledResizeHandler);
    
    return () => window.removeEventListener("resize", throttledResizeHandler);
  }, []);

  return width;
}

export default useWindowWidth;
