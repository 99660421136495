import 'aos/dist/aos.css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowEventListener } from 'rooks';
import { setTimerIsDone } from '../../state/reducers/timerReduser/timerReducer';
import '../../styles/styles.scss';
import Footer from '../Footer';
import Header from '../Header';
import ScrollTo from '../ScrollTo';

const Layout = ({ children }) => {
  const [timer, setTimer] = useState(0);
  const [show, setMenuShow] = useState(false);

  const handleCloseLangMenu = () => {
    !show ? setMenuShow(true) : setMenuShow(false);
  };

  const handleCloseMenuOutBorder = () => show && setMenuShow(false);

  useWindowEventListener("scroll", function () {
    handleCloseMenuOutBorder();
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (timer < 100) {
      setTimeout(() => {
        setTimer((time) => time + 1);
      }, 4);
    }
    dispatch(setTimerIsDone(true));

    // return () => clearInterval(id.current);
  }, [timer]);

  return (
    <>
      {timer < 100 && (
        <div className="d-flex vh-100 justify-content-center align-items-center progress-bar-count">
          {timer}
        </div>
      )}
      <Header handleClickLangDrop={handleCloseLangMenu} menuShow={show} />
      <div onClick={handleCloseMenuOutBorder} className="content mt-2">
        {children}
      </div>
      <ScrollTo />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
