export const langButtons = [
  {
    titleHeader: 'EN',
    titleFooter: 'English',
    value: 'en',
  },
  {
    titleHeader: 'RU',
    titleFooter: 'Русский',
    value: 'ru',
  },
  {
    titleHeader: 'UA',
    titleFooter: 'Українська',
    value: 'ua',
  },
];

export const options = {
  en: 'English',
  ru: 'Русский',
  ua: 'Українська',
};

export const backOptions = {
  English: 'en',
  Русский: 'ru',
  Українська: 'ua',
};
