import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
import { Navbar, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import Logo from '../../images/faceit-logo-black.svg';
import { Trans, useTranslation } from 'react-i18next';
import useDocumentScrollThrottled from '../../hooks/useDocumentScrollThrottled';
import useWindowWidth from '../../hooks/useWindowWidth';
import { breakpoints } from '../../constants/ui';
import {langButtons} from "../Common/footer&header/structureButtons";
import {languages} from "../Common/footer&header/filtratorFunc";

const MINIMUM_SCROLL_FROM_TOP = 80;
const MIN_DELTA_SCROLL_TO_TRIGGER = 15;

const Header = ({ menuShow, handleClickLangDrop }) => {
  const pageLocation = globalHistory?.location.pathname.split('/')[2]
  const langFromQuery = globalHistory?.location.pathname.split('/')[1];
  const vacancyStrForCheck = ['vacancy', 'vacancies']

  const [lang, setLange] = useState('');
  const [langs] = useState(languages(langButtons, pageLocation, vacancyStrForCheck, 'header'));
  const [mobileNavIsOpened, setMobileNavIsOpened] = useState(false);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const { i18n } = useTranslation();
  const pageWidth = useWindowWidth();

  const MENU = [
    { title: 'aboutUs', link: '/about-us' },
    { title: 'services', link: '/services' },
    { title: 'portfolio', link: '/portfolio' },
    { title: 'vacancies', link: '/vacancy' },
    { title: 'howWeWork', link: '/how-we-work' },
    { title: 'testimonials', link: '/testimonials' },
    { title: 'contacts', link: '/contact-us' },
  ];

  useDocumentScrollThrottled(
    ({ previousScrollTop, currentScrollTop }) => {
      if (
        !mobileNavIsOpened ||
        (mobileNavIsOpened && pageWidth >= breakpoints.xl)
      ) {
        if (currentScrollTop === 0) {
          setShouldHideHeader(false);
          return;
        }
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumFromTopScrolled =
          currentScrollTop > MINIMUM_SCROLL_FROM_TOP;
        const delta = Math.abs(previousScrollTop - currentScrollTop);
        if (delta >= MIN_DELTA_SCROLL_TO_TRIGGER) {
          setShouldHideHeader(isScrolledDown && isMinimumFromTopScrolled);
        }
      }
    },
    [mobileNavIsOpened, pageWidth]
  );

  useEffect(() => {
    setLange(langFromQuery);
    i18n.changeLanguage(langFromQuery).catch((err) => console.log(err));
    navigate(
      `/${langFromQuery}/${globalHistory.location.pathname
        .split('/')
        .slice(2)
        .join('/')}`
    );
  }, []);

  useEffect(() => {
    setMobileNavIsOpened(false);
    setShouldHideHeader(false);
  }, [pageWidth]);

  const updateLang = async (item) => {
    try {
      setLange(item);
      await i18n.changeLanguage(item);
      await navigate(
        `/${item}/${globalHistory.location.pathname
          .split('/')
          .slice(2)
          .join('/')}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavToggle = () => {
    setMobileNavIsOpened(!mobileNavIsOpened);
  };

  //!!!
  /* Attention this place change <body />, when the navbar is open. */
  useEffect(() => {
    if (mobileNavIsOpened) document.body.style.overflow = 'hidden';
    if (!mobileNavIsOpened) document.body.style.overflow = '';
  }, [mobileNavIsOpened]);
  
  return (
    <div className="header-wrap">
      <div
        className={classnames({
          header: true,
          'header--hidden':
            pageWidth >= breakpoints.xl
              ? shouldHideHeader
              : shouldHideHeader && !mobileNavIsOpened,
        })}
      >
        <Navbar
          className="navBar"
          bg="white"
          expand="xl"
          expanded={mobileNavIsOpened}
          onToggle={handleNavToggle}
        >
          <div className="container position-relative">
            <Link to={`/${lang}/`} className="svg navbar-brand">
              <img src={Logo} alt="" />
            </Link>

            <Navbar.Toggle aria-controls="navbarToggler" />

            <Navbar.Collapse id="navbarToggler">
              <div
                className="d-flex flex-column flex-xl-row justify-content-between
              flex-grow-1 navabarIn"
              >
                <div
                  className="header-left align-items-center flex-grow-1 justify-content-center d-none d-xl-block"
                />
                <div
                  className="header-right d-flex flex-column flex-xl-row
                  align-items-center pt-2 pt-md-0 flex-grow-1 flex-xl-grow-0"
                >
                  <ul
                    className="navbar-nav flex-xl-row
                    flex-xl-grow-0 text-sm-left text-md-center justify-content-xl-end align-items-center"
                  >
                    {MENU.map(
                      (menuItem) =>
                        i18n.t(`menu.${menuItem.title}`) && (
                          <li className="nav-item" key={menuItem.link}>
                            <Link
                              to={`/${lang}${menuItem.link}`}
                              className="nav-link"
                            >
                              <Trans i18nKey={`menu.${menuItem.title}`} />
                            </Link> 
                          </li>
                        )
                    )}
                  </ul>

                  <div className="lang">
                    <Dropdown 
                      navbar={true}
                      show={menuShow}
                      onClick={handleClickLangDrop}
                    >
                      <Dropdown.Toggle
                        className="btn btn-outline-dark rounded-circle p-0 ml-0 ml-xl-4"
                        as="button"
                        bsPrefix=" "
                      >
                        {lang}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="dropdown-menu dropdown-menu-right"
                      >
                        {langs.map(
                          (item) =>
                          {
                            return (
                            item !== lang && (
                              <Dropdown.Item
                                href="#"
                                key={item}
                                onClick={() => updateLang(item)}
                                className="dropdown-item"
                              >
                                {item}
                              </Dropdown.Item>
))}
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="d-xl-none d-flex w-100">
                    <div className="lang-sm d-flex justify-content-md-center ml-auto mr-auto">
                      {langButtons.map(({ titleHeader, value }) => {
                        const btnTypeClass = classnames({
                          'btn-primary': value === lang,
                          'btn-outline-secondary': value !== lang,
                        });
                        return (
                          <button
                            key={titleHeader}lang
                            className={`btn ${btnTypeClass} rounded-circle p-0`}
                            onClick={() => updateLang(value)}
                          >
                            {titleHeader}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
