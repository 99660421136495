import {langButtons} from "./structureButtons";
//

export const languages = (langArr, pageLocation, vacancyStrForCheck, toggleBetweenFooterHeader) => {
  let langArrForVacanciesPage = []
  let footer = 'footer'
  let header = 'header'
  if (toggleBetweenFooterHeader === header) {
    langArr.filter(({value}) => {
      if (vacancyStrForCheck.includes(pageLocation)) {
        langArrForVacanciesPage.push(value)
      } else if (!vacancyStrForCheck.includes(pageLocation)) {
        langArrForVacanciesPage.push(value)
      }
    })
  }
  if (toggleBetweenFooterHeader === footer) {
    langArr.filter(({titleFooter}) => {
      if (vacancyStrForCheck.includes(pageLocation)) {
        langArrForVacanciesPage.push(titleFooter)
      } else if (!vacancyStrForCheck.includes(pageLocation)) {
        langArrForVacanciesPage.push(titleFooter)
      }
    })
  }
  return langArrForVacanciesPage
}
